body {
  background-color: #f1f1f1;
}

 :root {
  --cor-primaria: hsl(208, 80%, 49%);
  --cor-primaria-h: 208;
  --cor-primaria-s: 80%;
  --cor-primaria-l: 49%;
  --cor-secundaria: hsl(145, 63%, 49%);
  --cor-secundaria-h: 145;
  --cor-secundaria-s: 63%;
  --cor-secundaria-l: 49%;
  --cor-terciaria: hsl(207, 86%, 20%);
  --cor-terciaria-h: 207;
  --cor-terciaria-s: 86%;
  --cor-terciaria-l: 20%;
 }

 .mensagem__texto {
  overflow-wrap: anywhere;
 }