.botaoLogo {
  padding: 0;
  width: fit-content;
}

.paginaAplicativos {
  & > img {
    width: 85px;
    height: auto;
  }
}

.botaoHamburguer {
  display: none;
  @media screen and (max-width: 880px) {
    display: block;
  }
}

.submenu {
  width: fit-content !important;
  right: 0 !important;
}

.menuLista {
  @media screen and (max-width: 880px) {
    display: none;
  }
}

.menuLateral {
  position: absolute;
  width: 300px;
  height: 100%;
  @media screen and (min-width: 880px) {
    display: none;
  }
  :global(.dropdown__lista) {
    max-width: 264px;
    left: 16px !important;
  }
  &~:global(.pagina__conteudo-container ) {
   margin-left: 0;
  }
}

.divSelect {
  border: 1px solid #E0E0E0;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 700;
  display: flex;
  align-items: center;
  border-radius: 8px;
  height: 40px;
  margin-top: 8px;
  padding-left: 8px;
  color: #FFFFFF;
}

.selectSemPreenchimento{
  background-color: transparent;
  color: rgba(255, 255, 255, 0.75);
  border: none;
}

.opcaoSelect{
  color: #616161;
  background-color: transparent;
}